
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    // init
    const router = useRouter();

    const gotoDashboard = (data) => {
      router.push({ name: "app.dashboard" });
    };

    return {
      gotoDashboard,
    };
  },
});
